import classNames from "classnames";
import Title from "../../components/Title/Title";
import "./LetuPromoPage.css";
import slideLetu from "../../assets/images/banners/letu.png";
import {Swiper, SwiperSlide} from "swiper/react";
import Slide from "../../components/Slides/Slide";
import { CLIENT_ID } from "../../constants";
import { useHistory } from "react-router-dom";

export const LetuPromoPage = () => {
      const history = useHistory();
      
  return (
    <div className={"letu-promo-page"}>
      <div className={"letu-promo-page__slider"}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          autoplay
          pagination={{ clickable: true }}
        >
          <SwiperSlide>
            <Slide
              title1=""
              title2=""
              background={slideLetu}
              points1={0}
              points2={0}
              opacity={1}
              one
              buttonText="Заказать сертификат"
              buttonOnClick={() => history.push("/products/119")}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <Title as="h1">Условия акции</Title>
      <div className={"letu-promo-page__text"}>
        Все довольно просто! Выбирайте сертификаты ЛЭТУАЛЬ со скидкой 10%, а
        внутри сертификата Вы найдете промокод на 10000 бонусов (для их
        активации потребуется личный кабинет в интернет-магазине). С полными
        правилами использования промокода и инструкцией по его активации можно
        ознакомиться на официальном{" "}
        <a href="https://www.letu.ru/promo/15090020">сайте</a>.
      </div>
    </div>
  );
};
